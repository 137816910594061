@tailwind base;
@tailwind components;
@tailwind utilities;

*{
    line-height: 1.5;
    /* overflow:hidden; */
    max-width: 1280px;    
}

html {
    scroll-behavior: smooth;
    margin: 0 auto;
  }

.tp{
    padding-top: 40px;
}

@keyframes slide-in-left {
    0% {
      transform: translateX(-100%);
      opacity: 0;
    }
    100% {
      transform: translateX(0);
      opacity: 1;
    }
  }